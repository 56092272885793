import React from 'react';
import './Mains.css';
import Header from '../Header/Header';
import hero_image from "../../assets/hero_image.png";
import hero_image_back from "../../assets/hero_image_back.png";
import Heart from "../../assets/heart.png";
import Calories from "../../assets/calories.png";
import {motion} from 'framer-motion'


const Mains = () => {
    const transition = {type: 'spring', duration: 3};
    const mobile = window.innerWidth<=768 ? true: false;
  return (
    <div className='mains'>

        <div className="blur mains-blur"></div>
        <div className="left-m">
            <Header/>
{/* the add section*/}
            <div className="the-best-add">
                <motion.div
                initial={{left: mobile? '165px': '238px'}}
                whileInView={{left: '8px'}}
                transition={{...transition, type: 'tween'}}
                
                ></motion.div>
                <span>The Best fitness Club in Bijnor</span>
            </div>
 {/*the heading*/}      
            <div className='heading'>
                <div>
                    <span className='stroke-text'>Shape </span>
                    <span>Your</span>
                </div>
                <div>
                    <span>Ideal Body</span>
                </div>
            </div>
            <div className='span'>
                <span>Here we will help you to shape and build your body.</span>
            </div>

    {/*figures/data*/}

            <div className="figures">
                <div>
                    <span>+25</span>
                    <span>expert coaches</span>
                </div>
                <div>
                    <span>+1000</span>
                    <span>members joined</span>
                </div>
                <div>
                    <span> +18</span>
                    <span>fitness program</span>
                </div>
               
            </div> 
    {/*button*/}
            <div className="m-button">
                <button className='btn'>Get Started  </button>
                <button className='btn'> Learn More</button>
            </div>       

        </div>
        <div className="right-m">
            <button className='btn'>Join Now</button>

            <motion.div 
            initial={{right: "-1rem"}}
            whileInView={{ right: "4rem"}}
            transition={transition}
            
             className="heart-rate">
                <img src={Heart} alt="" />
                <span>Heart Rate</span>
                <span>116 bpm</span>
            </motion.div>
            <motion.div
              initial={{right: "37rem"}}
              whileInView={{ right: "28rem"}}
              transition={transition}
              className="calories">
                <img src={Calories} alt="" />
                <div>
                <span>Calories burned</span>
                <span>220 kcal</span>
                </div>
            </motion.div>



            <div>
                <img src={hero_image} alt="" className='hero-image'/>
                <motion.img 
                initial={{right: "11rem"}}
                whileInView={{right: "20rem"}}
                transition={transition}
                src={hero_image_back} alt="" className='hero-img-back'/>
            </div>

        </div>

    </div>
  )
}

export default Mains
